<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;"
							@change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-form-item label="交易时间" class="ui-form__item">
						<a-range-picker v-model:value="time" :disabledDate="disabledDate" @calendarChange="onCalendarChange"></a-range-picker>
					</a-form-item>

					<a-form-item label="配送方式" name="deliveryType" class="ui-form__item">
						<a-select v-model:value="formState.deliveryType" placeholder="请选择配送方式" style="width: 190px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">无</a-select-option>
							<a-select-option :value="2">自提</a-select-option>
							<a-select-option :value="3">快递</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="营销方式" name="goodsBuyType" class="ui-form__item">
						<a-select v-model:value="formState.goodsBuyType" placeholder="请选择营销方式" style="width: 190px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="0">普通</a-select-option>
							<a-select-option :value="1">砍价活动</a-select-option>
							<a-select-option :value="2">秒杀活动</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="发货状态" name="orderStatus" class="ui-form__item">
						<a-select v-model:value="formState.orderStatus" placeholder="请选择发货状态" style="width: 190px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">未发货</a-select-option>
							<a-select-option :value="2">在途中</a-select-option>
							<a-select-option :value="3">已收货</a-select-option>
							<a-select-option :value="5">未核销</a-select-option>
							<a-select-option :value="6">已核销</a-select-option>
							<a-select-option :value="7">已完成</a-select-option>
							<a-select-option :value="4">已退款</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row v-show="showAll">
					<!-- <a-form-item label="会员账号" name="memberCode" class="ui-form__item">
						<a-input v-model:value="formState.memberCode" placeholder="请输入会员账号"></a-input>
					</a-form-item> -->

					<a-form-item label="会员手机" name="phone" class="ui-form__item">
						<a-input v-model:value="formState.phone" placeholder="请输入会员手机"></a-input>
					</a-form-item>

					<a-form-item label="商品名称" name="goodsTitle" class="ui-form__item">
						<a-input v-model:value="formState.goodsTitle" placeholder="请输入商品名称"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" label="订单来源" name="source">
						<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">微信小程序</a-select-option>
							<a-select-option :value="2">抖音小程序</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="订单编号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单编号"></a-input>
					</a-form-item>

					<a-form-item label="商户流水号" name="outTradeNo" class="ui-form__item">
						<a-input v-model:value="formState.outTradeNo" placeholder="请输入商户流水号"></a-input>
					</a-form-item>

					<a-form-item label="快递单号" name="logisticsNo" class="ui-form__item">
						<a-input v-model:value="formState.logisticsNo" placeholder="请输入快递单号"></a-input>
					</a-form-item>

					<a-form-item label="收货手机" name="receiverPhone" class="ui-form__item">
						<a-input v-model:value="formState.receiverPhone" placeholder="请输入收货人手机"></a-input>
					</a-form-item>

					<a-form-item label="支付状态" name="payStatus" class="ui-form__item">
						<a-select v-model:value="formState.payStatus" placeholder="请选择支付状态" style="width: 190px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">未支付</a-select-option>
							<!-- <a-select-option :value="2">支付中</a-select-option> -->
							<a-select-option :value="3">支付成功</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="平台发起提现" name="isSubLedger">
						<a-select style="width: 190px;" placeholder="选择取票状态" v-model:value="formState.isSubLedger">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">已发起</a-select-option>
							<a-select-option :value="0">未发起</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['mall_order_list_export']">
							<exportComponent type="storeOrder" :searchData="searchData"></exportComponent>
						</span>
						<!-- <a-button v-permission="['mall_order_list_export']" type="primary" ghost @click="title = '';exportVisible = true;">导出</a-button> -->
						<a-button v-permission="['mall_order_list_import']" type="primary" style="margin-left: 20px;"
							@click="onImport">导入快递单号</a-button>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="orderId"
				:pagination="pagination" :scroll="{ x: 2900 }">
				<template #bodyCell="{ column, record, index }">
					<template v-if="column.key === 'store'">
						{{ record.orderStoreVO.name }}
					</template>

					<template v-if="column.key === 'source'">
						<div>
							<a-tag color="green" v-if="record.source === 1">
								微信小程序
							</a-tag>
							<a-tag color="blue" v-else> 抖音小程序 </a-tag>
						</div>
					</template>

					<template v-if="column.key === 'tradeInfo'">
						<div>交易单号：<span>{{ record.orderNo }}</span></div>
						<div>交易流水号：<span>{{ record.outTradeNo }}</span></div>
						<div>交易状态：<span>{{ transStatus(record.status, record.deliveryType) }}</span></div>
						<div>账单金额：<span style="color: orange;">￥{{ record.totalPrice }}</span></div>
						<div>账单时间：<span>{{ transDateTime(record.createTime) }}</span></div>
					</template>

					<template v-else-if="column.key === 'userInfo'">
						<!-- <div>{{ record.memberCode || '' }}</div> -->
						<div>会员昵称：{{ record.nickname || '-' }}</div>
						<div>会员手机：{{ record.phone || '-' }}</div>
					</template>

					<template v-else-if="column.key === 'goodsInfo'">
						<div style="display: flex;align-items: flex-start;" v-for="item in record.goodsInfoVOS"
							:key="item.id">
							<a-image style="width: 60px;border-radius: 2px;" :src="item.imgUrl.split(',')[0]"></a-image>
							<div style="margin-left: 8px;">
								<div>{{ item.mainTitle }}</div>
								<div style="font-size: 18px;color: orange;">￥{{ item.nowPrice }}</div>
							</div>
						</div>
					</template>

					<template v-else-if="column.key === 'goodsBuyType'">
						<div>
							<div v-if="record.goodsBuyType">{{ ['普通', '砍价活动', '秒杀活动'][record.goodsBuyType] }}</div>
							<div v-else>--</div>
						</div>
					</template>

					<template v-else-if="column.key === 'cardInfo'">
						<div v-if="record.goodsInfoVOS[0].isCard">
							<div v-if="record.goodsInfoVOS[0].isCard">
								<a-tag :color="['orange', 'blue'][record.goodsInfoVOS[0].cardType - 1]"
									   :style="{ color: ['#fa8c16', '#108ee9'][record.goodsInfoVOS[0].cardType - 1] }"
								>
									{{ ['次卡', '年卡'][record.goodsInfoVOS[0].cardType - 1] }}
								</a-tag>
								<div v-if="record.cardTitle">卡等级：{{ record.cardTitle }}</div>
								<div v-if="record.cardNumber">卡号：{{ record.cardNumber }}</div>

								<div v-if="record.goodsInfoVOS[0].cardType === 1">
									开卡次数：{{ record.goodsInfoVOS[0].cardBalanceTimes }}</div>
								<!-- <div>赠送次数：{{ record.cardGiftTimes }}</div> -->
							</div>
						</div>
						<div v-if="record.goodsInfoVOS[0].isRechargeCard">
							<a-divider style="margin: 6px 0;" v-if="record.goodsInfoVOS[0].isCard"/>
							<a-tag color="cyan" style="color: #2db7f5;">线上会员卡</a-tag>
							<div v-if="record.rechargeCardTitle">卡等级：{{ record.rechargeCardTitle }}</div>
							<div v-if="record.rechargeCardNumber">卡号：{{ record.rechargeCardNumber }}</div>
							<div>初始金额：{{ record.initAmount }}元</div>
						</div>
						<div v-if="!record.goodsInfoVOS[0].isCard && !record.goodsInfoVOS[0].isRechargeCard">--</div>
					</template>

					<template v-else-if="column.key === 'couponInfo'">
						<div v-if="record.goodsInfoVOS[0].isCoupon">
							<div style="margin-bottom: 5px;" v-if="record.goodsInfoVOS[0].ticketBatchNum">
								<div>兑换券批次号：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVOS[0].ticketCouponList.length ? record.goodsInfoVOS[0].ticketCouponList[0].batchNo : '' }}</span></div>
								<div>兑换券发放数量：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVOS[0].ticketBatchNum}}张</span></div>
								<div>
									兑换券号：
									<a-tag v-for="(item, i) in record.goodsInfoVOS[0].ticketCouponList" :key="i">
										{{ item.barcode }}
									</a-tag>
								</div>
							</div>
							<div v-if="record.goodsInfoVOS[0].snackBatchNum">
								<a-divider style="margin: 6px 0;" v-if="record.goodsInfoVOS[0].ticketBatchNum"/>
								<div>卖品券批次号：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVOS[0].snackCouponList.length ? record.goodsInfoVOS[0].snackCouponList[0].batchNo : '' }}</span></div>
								<div>卖品券发放数量：<span style="color: rgb(102, 102, 102);">{{record.goodsInfoVOS[0].snackBatchNum}}张</span></div>
								<div>
									卖品券号：
									<a-tag v-for="(item, i) in record.goodsInfoVOS[0].snackCouponList" :key="i">
										{{ item.barcode }}
									</a-tag>
								</div>
							</div>
						</div>
						<span v-else>--</span>
					</template>

					<template v-else-if="column.key === 'deliveryInfo'">
						<div style="text-align: center;">
							<div>配送方式：{{ ['无', '自提', '快递'][record.deliveryType - 1] }}</div>
							<div v-if="record.deliveryType === 2" :id="'copy' + index">{{ record.verificationCode }}
							</div>
							<a v-if="record.deliveryType === 2 && record.verificationCode"
								@click="onCopy(index)">复制核销码</a>
						</div>
					</template>

					<template v-else-if="column.key === 'statusInfo'">
						<div v-if="record.deliveryType !== 3">
							核销状态：<span
								style="color: #fd6846;">{{ transStatus(record.status, record.deliveryType) }}</span>
							<div v-if="record.verificationUserName">
								<div>核销人员：{{ record.verificationUserName }}</div>
								<div>{{ transDateTime(record.finishTime) }}</div>
							</div>
						</div>
						<div v-else>
							发货状态：<span
								style="color: #fd6846;">{{ transStatus(record.status, record.deliveryType) }}</span>
							<div>运费：{{ record.deliveryFee }}</div>
							<div v-if="record.sendGoodsUserName">
								<div>发货人员：{{ record.sendGoodsUserName }}</div>
								<div>{{ transDateTime(record.sendGoodsTime) }}</div>
							</div>
						</div>
					</template>

					<template v-else-if="column.key === 'receiptInfo'">
						<span v-if="record.deliveryType !== 3">--</span>
						<div v-else>
							<div>{{ record.orderAddress.name }}</div>
							<div>{{ record.orderAddress.phone }}</div>
							<div>{{ record.orderAddress.showAddress }}</div>
						</div>
					</template>

					<template v-else-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>

					<template v-else-if="column.key === 'refund'">
						<div v-if="record.status === 'AFTERSALE_FINISH'">
							<div style="color: red;">已退款</div>
							<div>退款{{ record.orderAfterSale ? record.orderAfterSale.refund : '' }}元<span
									v-if="record.refundRemark">，{{ record.refundRemark || '' }} </span></div>
						</div>
						<div v-else>--</div>
					</template>

					<template v-else-if="column.key === 'isSubLedger'">
						{{ record.isSubLedger ? '已发起' :'未发起' }}
					</template>

					<template v-else-if="column.key === 'action'">
						<a-button type="link" v-permission="['mall_order_list_refund']"
							v-if="['WAIT_SELLER_SEND_GOODS', 'WAIT_CHECK', 'WAIT_BUYER_CONFIRM_GOODS', 'WAIT_COMMENT', 'TRADE_FINISH'].indexOf(record.status) !== -1"
							@click="onRefund(record)">售后退款</a-button>
						<div v-if="record.deliveryType === 2">
							<a v-permission="['mall_order_list_verification']" v-if="record.status === 'WAIT_CHECK'"
								@click="onVerification(record)">确认核销</a>
							<a v-else-if="record.status === 'TRADE_FINISH'" disabled style="color: #CCC;">已核销</a>
							<span v-else>--</span>
						</div>
						<div v-else-if="record.deliveryType === 3">
							<a v-permission="['mall_order_list_send']" v-if="record.status === 'WAIT_SELLER_SEND_GOODS'"
								@click="onSend(record)">点击发货</a>
							<div
								v-else-if="['WAIT_BUYER_CONFIRM_GOODS', 'WAIT_COMMENT', 'TRADE_FINISH'].includes(record.status)">
								<a disabled style="color: #CCC;">已发货</a>
								<div><a @click="onLogistics(record)">查看物流</a></div>
							</div>
							<span v-else>--</span>
						</div>
						<div v-else>--</div>
					</template>
				</template>
			</a-table>

			<a-modal v-model:visible="refundVisible" title="退款" @cancel="onRefundCancel" @ok="onRefundOk">
				<a-spin :spinning="loading">
					<a-form ref="refundForm" name="refundForm" :model="refundData">
						<a-form-item label="退款金额" name="refund" :rules="[{required: true, message: '请输入退款的备注和金额'}]">
							<a-input-number v-model:value="refundData.refund" :min="0" placeholder="请输入">
							</a-input-number>
						</a-form-item>

						<a-form-item label="退款备注" name="remark" :rules="[{required: true, message: '请输入退款的备注和金额'}]">
							<a-textarea v-model:value="refundData.remark" placeholder="请输入退款的备注和金额"></a-textarea>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>

			<a-modal v-model:visible="importVisible" title="导入需发货的订单信息" width="750px" @ok="onImportOk">
				<a-form ref="importFrom" name="importFrom" :model="modelRef" :labelCol="{span: 6 }"
					:wrapperCol="{span: 14 }">
					<a-form-item label="下载模板">
						<a href="/dashboard/导入发货模板.xlsx">下载模板</a>
					</a-form-item>

					<a-form-item label="Excel文件" name="fileList" :rules="[{required: true, message: '请上传文件'}]">
						<a-upload v-model:file-list="modelRef.fileList" list-type="picture"
							action="/admin/ajaxUpload.do" accept=".xlsx" :max-count="1" :beforeUpload="beforeUpload">
							<a-button>
								<Icon icon="UploadOutlined"></Icon>
								上传
							</a-button>
						</a-upload>
					</a-form-item>
				</a-form>
			</a-modal>

			<a-modal v-model:visible="sendModal" title="填写物流信息" width="750px" @ok="onSendOk">
				<a-spin :spinning="loading">
					<a-form ref="sendForm" name="sendForm" :model="sendModelRef" :labelCol="{span: 6 }"
						:wrapperCol="{span: 14 }">
						<a-form-item label="物流公司" name="logisticsCompany"
							:rules="[{required: true, message: '必填项不允许为空'}]">
							<a-select v-model:value="sendModelRef.logisticsCompany" placeholder="请选择快递公司">
								<a-select-option value="SF">顺丰速运</a-select-option>
								<a-select-option value="HTKY">百世快递</a-select-option>
								<a-select-option value="ZTO">中通快递</a-select-option>
								<a-select-option value="STO">申通快递</a-select-option>
								<a-select-option value="YTO">圆通速递</a-select-option>
								<a-select-option value="YD">韵达速递</a-select-option>
								<a-select-option value="YZPY">邮政快递包裹</a-select-option>
								<a-select-option value="EMS">EMS</a-select-option>
								<a-select-option value="HHTT">天天快递</a-select-option>
								<a-select-option value="UC">优速快递</a-select-option>
								<a-select-option value="DBL">德邦快递</a-select-option>
								<a-select-option value="JD">京东快递</a-select-option>
								<a-select-option value="SNWL">苏宁物流</a-select-option>
								<a-select-option value="SX">顺心捷达</a-select-option>
								<a-select-option value="JTSD">极兔速递</a-select-option>
							</a-select>
						</a-form-item>

						<a-form-item label="快递单号" name="logisticsNo" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-input v-model:value="sendModelRef.logisticsNo" placeholder="请填写快递单号" />
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>

			<a-modal v-model:visible="tracesVisible" title="物流信息">
				<template #footer>
					<a-button type="primary" @click="tracesVisible = false;">关闭</a-button>
				</template>
				<div style="margin-bottom: 20px;">
					<p>物流公司：{{toLogisticsCompany(traces.shipperCode)}}</p>
					<p>物流单号：{{traces.logisticCode || '--'}}</p>
				</div>

				<div class="code-box-demo">
					<ul class="ant-timeline" v-if="traces.traces && traces.traces.length != 0">
						<li class="ant-timeline-item" v-for="(t, i) in traces.traces" :key="i">
							<div class="ant-timeline-item-tail" v-if="i !== (traces.traces.length - 1)"></div>
							<div class="ant-timeline-item-head ant-timeline-item-head-blue"></div>
							<div class="ant-timeline-item-content">
								<p style="font-weight: 700;">{{ t.acceptStation }}</p>
								<p style="margin: 5px;">{{ t.acceptTime }}</p>
							</div>
						</li>
					</ul>
					<div v-else style="text-align: center;color: #666666;">
						暂无物流信息
					</div>
				</div>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import {
		getOrganizationList,
		getCinemaList
	} from '@/service/modules/cinema.js';
	import {
		getOrderBaseList,
		verificationOrderBase,
		sendOrderBase,
		importOrderBase,
		orderAfterSale
	} from '@/service/modules/mall.js';
	import {
		orderTraces
	} from '@/service/modules/order.js';
	export default {
		components: {
			Icon,
			exportComponent
		},
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				time: [],
				formState: {
					saleType: 1,
					// organizationId: 0,
					cinemaId: 0,
					isSubLedger: '',
					source: 0,
					goodsBuyType: ''
				},
				searchData: {},
				list: [],
				columns: [{
					title: '商城名称',
					key: 'store'
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '交易信息',
					key: 'tradeInfo',
					width: 350
				}, {
					title: '会员信息',
					key: 'userInfo'
				}, {
					title: '商品信息',
					key: 'goodsInfo'
				}, {
					title: '卡信息',
					key: 'cardInfo',
					width: 250
				},
				{
				 	title: '券信息',
				 	key: 'couponInfo'
				},
				{
					title: '配送方式',
					key: 'deliveryInfo',
					align: 'center'
				}, {
					title: '营销方式',
					align: 'center',
					key: "goodsBuyType",
					width: 100,
				}, {
					title: '发货/核销信息',
					key: 'statusInfo'
				}, {
					title: '收货信息',
					key: 'receiptInfo'
				}, {
					title: '交易时间',
					width: 180,
					key: 'createTime'
				}, {
					title: '售后退款备注',
					key: 'refund'
				}, {
					title: '平台发起提现',
					key: 'isSubLedger',
					width: 120
				}, {
					title: '操作',
					key: 'action',
					width: 130,
					align: 'center',
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				importVisible: false,
				modelRef: {
					fileList: [],
				},
				sendModal: false,
				sendModelRef: {},
				sendOrderItem: {},
				refundVisible: false,
				refundData: {},
				tracesVisible: false,
				traces: {},
			}
		},
		created() {
			this.time = [this.moment().subtract(1,'months').startOf('day'), this.moment().endOf('day')];
			this.getOrganizationList();
			// this.onSearch();
		},
		methods: {
			toLogisticsCompany(logisticsCompany) {
				switch (logisticsCompany) {
					case 'SF':
						return "顺丰速运";
					case 'HTKY':
						return "百世快递";
					case 'ZTO':
						return "中通快递";
					case 'STO':
						return "申通快递";
					case 'YTO':
						return "圆通速递";
					case 'YD':
						return "韵达速递";
					case 'YZPY':
						return "邮政快递包裹";
					case 'EMS':
						return "EMS";
					case 'HHTT':
						return "天天快递";
					case 'UC':
						return "优速快递";
					case 'DBL':
						return "德邦快递";
					case 'JD':
						return "京东快递";
					case 'SNWL':
						return "苏宁物流";
					case 'SX':
						return "顺心捷达";
					case 'JTSD':
						return "极兔速递";
					default:
						return "-";
				}
			},
			transStatus(val, type) {
				if (val === 'WAIT_BUYER_PAY') {
					return '待支付';
				}
				if (val === 'SYSTEM_CANCEL') {
					return '系统超时取消';
				}
				if (val === 'CUSTOMER_CANCEL') {
					return '用户自行取消';
				}
				if (val === 'WAIT_SELLER_SEND_GOODS') {
					return '待发货'
				}
				if (val === 'WAIT_CHECK') {
					return '待核销'
				}
				if (val === 'TRADE_FINISH') {
					if (type === 2) {
						return '已核销'
					} else {
						return '订单完成'
					}
				}
				if (val === 'WAIT_BUYER_CONFIRM_GOODS') {
					return '待收货'
				}
				if (val === 'AFTERSALE_FINISH') {
					return '已退款'
				}
				if (val === 'WAIT_COMMENT') {
					return '已签收'
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				console.log(this.searchData)
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.deliveryType = this.searchData.deliveryType ? this.searchData.deliveryType : undefined;
				this.searchData.orderStatus = this.searchData.orderStatus ? this.searchData.orderStatus : undefined;
				this.searchData.payStatus = this.searchData.payStatus ? this.searchData.payStatus : undefined;
				this.searchData.isSubLedger = this.searchData.isSubLedger ? this.searchData.isSubLedger : undefined;
				this.searchData.goodsBuyType = this.searchData.goodsBuyType != '' ? this.searchData.goodsBuyType : undefined;
				if (this.time && this.time.length) {
					this.searchData.startTime = (this.time[0].startOf('day').valueOf() / 1000).toFixed(0);
					this.searchData.endTime = (this.time[1].endOf('day').valueOf() / 1000).toFixed(0);
				}
				this.getData();
			},
			reset() {
				this.time = [this.moment().subtract(1,'months').startOf('day'), this.moment().endOf('day')];
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getOrderBaseList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						saleType: 1,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onVerification(item) {
				this.$confirm({
					title: '提示',
					content: '确定核销完成吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await verificationOrderBase({
								orderId: item.orderId
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('核销成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			disabledDate(current) {
				if (!this.time || this.time.length === 0) {
				    return current && current > this.moment().endOf('day');
				}
			 	const tooLate = this.time[0] && current.diff(this.time[0], 'days') > 31;
				const tooEarly = this.time[1] && this.time[1].diff(current, 'days') > 31;
				const tooCurrent = current && current > this.moment().endOf('day');
			    return tooEarly || tooLate || tooCurrent;
			},
			onCalendarChange(value) {
				this.time = value || [];
			},
			onSend(item) {
				this.sendModelRef = {
					id: item.orderId
				};
				this.sendModal = true;
			},
			onSendOk() {
				this.$refs.sendForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await sendOrderBase({
							id: this.sendModelRef.id,
							logisticsCompany: this.sendModelRef.logisticsCompany,
							logisticsNo: this.sendModelRef.logisticsNo
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('发货成功');
							this.sendModelRef = {};
							this.sendModal = false;
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onRefund(item) {
				this.refundData = {
					id: item.orderId
				};
				this.refundVisible = true;
			},
			onRefundCancel() {
				this.refundData = {};
				this.refundVisible = false;
			},
			onRefundOk() {
				this.$refs.refundForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await orderAfterSale({
							orderId: this.refundData.id,
							refund: this.refundData.refund,
							description: this.refundData.remark
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('退款成功');
							this.onRefundCancel();
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onCopy(index) {
				const range = document.createRange();
				range.selectNode(document.querySelector("#copy" + index));
				const selection = window.getSelection();
				if (selection.rangeCount > 0) selection.removeAllRanges();
				selection.addRange(range);
				document.execCommand('Copy');
				this.$message.success('复制成功');
			},
			onImport() {
				this.modelRef = {};
				this.importVisible = true;
			},
			onImportOk() {
				if (this.modelRef.fileList.length === 0) {
					return this.$message.warn('请先上传文件');
				}
				const isLt1M = this.modelRef.fileList[0].size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许1M');
				}
				this.$refs.importFrom.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await importOrderBase({}, this.modelRef.fileList[0]);
						this.loading = false;
						if (ret.code === 200) {
							this.importVisible = false;
							this.modelRef = {};
							this.$message.success('批量发货成功');
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			async onLogistics(record) {
				this.loading = true;
				try {
					let ret = await orderTraces({
						orderId: record.orderId
					})
					this.loading = false;
					if (ret.code === 200) {
						this.traces = ret.data;
						this.tracesVisible = true;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许1M');
				}
				return false;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
